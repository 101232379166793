body.dark-layout {
  .animated-background {
    background: rgb(40, 48, 70);
    background: linear-gradient(
      90deg,
      rgba(40, 48, 70, 1) 0%,
      rgba(27, 34, 50, 1) 31%,
      rgba(40, 48, 70, 1) 100%
    );
  }

  .badge-small-custom {
    box-shadow: none;
  }
}

.animated-background {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f6f6;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  // background: linear-gradient(to right, #e4e4e4 8%, #cac8c8 18%, #e4e4e4 33%);
  background-size: 1200px 104px;
  background-repeat: no-repeat;
  position: relative;
  min-height: 10px;
}

// React select
.react-select {
  &.is-invalid {
    .select__control {
      border-color: #dc3545;
      padding-right: calc(1.5em + 0.75rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }
}

.with-select {
  .react-select {
    flex: 3;
  }
}

p {
  word-break: break-word;
}
.json-to-table {
  td {
    word-break: break-word;
  }
}
