//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================
//$primary: #5058b8;
$primary: #632ca6;
$primary-dark: #272d6c;
$success: #28c76f;
$success-dark: #246e45;
$danger: #ea5455;
$danger-dark: #8a2c2c;
$secondary: #82868b;
$secondary-dark: #64676b;
$warning: #ff9f43;
$warning-dark: #af7136;
$info: #00cfe8;
$info-dark: #2e818a;
$dark: #4b4b4b;
$black: #000000;
$gray: gray;

$modal-fade-transform: scale(0.8);
$font-family-sans-serif: "Gilroy-Regular", Helvetica, Arial, serif !default;
$font-family-monospace: "Gilroy-Regular", Helvetica, Arial, serif !default;
$body-color: #000;
